import {defineStore} from 'pinia'
import type {GetEventTicketsByUserIdAndEventIdRb} from "~/models/request/getEventTicketsByUserIdAndEventIdRb";

export const ticketStore = defineStore({
    id: 'ticket-store',
    persist: true,
    state: () => {
        return {
            attendanceTickets: <any>[]
        }
    },
    actions: {
        setAttendanceTickets: function (tickets) {
            this.attendanceTickets = tickets;
        },
        addTicketsToAttendanceTicketsStore: function (ticket) {
            let isTicketExist = this.attendanceTickets.some(attTicket => attTicket.id === ticket.id);
            if (!isTicketExist) {
                this.attendanceTickets.push(ticket);
            }
        },
        // Prepare tickets by payment token
        prepareEventTicketsByPaymentToken: async function (paymentToken:string) {
            const config = useRuntimeConfig()
            let url = '/api/ticket/prepare-tickets-by-payment-token';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, {paymentToken},null, "prepareEventTicketsByPaymentToken" + new Date().getMilliseconds() + "_")
            return Result;
        },

        // Update ticket
        updateEventTicket: async function (studentId:number, ticketId:number) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/fee/updateTicket';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, {studentId, ticketId},null, "updateEventTicket" + new Date().getMilliseconds() + "_")
            return Result;
        },

        // Update ticket
        updateEventTicketServer: async function (studentId:number, ticketId:number) {
            const config = useRuntimeConfig()
            let url = '/api/ticket/update-ticket';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, {studentId, ticketId},null, "updateEventTicket" + new Date().getMilliseconds() + "_")
            return Result;
        },

        // Get tickets by payment token
        loadEventTicketsByPaymentToken: async function (paymentToken:string) {
            const config = useRuntimeConfig()
            let url = '/api/ticket/get-tickets-by-payment-token';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, {paymentToken},null, "loadEventTicketsByPaymentToken" + new Date().getMilliseconds() + "_")
            return Result;
        },

        // Get tickets by user id and event id
        getEventTicketsByUserIdStudentIdAndEventId: async function (getEventTicketsByUserIdAndEventIdRb: GetEventTicketsByUserIdAndEventIdRb) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/fee/getTicketsByUserIdAndClubId';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, getEventTicketsByUserIdAndEventIdRb,null, "getEventTicketsByUserIdAndEventId" + new Date().getMilliseconds() + "_")
            return Result;
        },

        getEventTicketsByUserIdStudentIdAndEventIdServer: async function (getEventTicketsByUserIdAndEventIdRb: GetEventTicketsByUserIdAndEventIdRb) {
            const config = useRuntimeConfig()
            let url = '/api/ticket/get-tickets-by-user-student-club-id';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, getEventTicketsByUserIdAndEventIdRb,null, "getEventTicketsByUserIdStudentIdAndEventIdServer" + new Date().getMilliseconds() + "_")
            return Result;
        },
    },
    getters: {
        getAttendanceTickets: state=>function (){
            return state.attendanceTickets;
        }
    },
})